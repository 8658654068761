// Libraries
import React from 'react';
import Link from 'next/link';

// Styles
import classes from './HomeNavigation.module.scss';

// Constants
import {MAIN_NAVIGATION_ITEMS} from '../../constants/routeConstants';


export default function HomeNavigation({className}) {
    const navigationItems = MAIN_NAVIGATION_ITEMS;

    const renderNavigationElementContent = (item) => (
        <>
            <img className={classes.navigationElementImg + ' ' + (item.isDisabled ? 'grayscale' : '')} src={item.image}
                alt=''/>
            <div className={classes.navigationElementTitle}>{item.title}</div>
            {item.isDisabled && <div className={classes.navigationElementHelpText}>Coming soon</div>}
        </>
    );

    return (
        <div
            className={className + ' display--flex flex__flow--wrap flex__alignItems--center flex__justifyContent--center'}
            style={{maxWidth: 1000}}>
            {
                navigationItems.map((item, index) => (
                    (!item.link || item.isDisabled)
                        ?
                        <div key={index}
                            className={`${classes.navigationElementBox} disabled`}>
                            {renderNavigationElementContent(item)}
                        </div>
                        :
                        <Link key={index} href={item.link}>
                            <a className={`${classes.navigationElementBox}`}>
                                {renderNavigationElementContent(item)}
                            </a>
                        </Link>
                ))
            }
        </div>
    );
}