// Libraries
import React from 'react';

// Styles
import classes from './HomeGreetings.module.scss';
import {useSelector} from 'react-redux';

const HomeGreetings = () => {
    const userData = useSelector((state) => state?.userData);
    const {getUserDetailsResponse} = userData;

    return (
        <div className='display--flex flex__alignItems--center flex__justifyContent--start'>
            <img alt='' className={classes.sealImg} src='/assets/images/seal.png'/>
            <div className='marginLeft--l2'>
                <div className='fontSize--h3'>Hey {getUserDetailsResponse?.displayName || 'Famstar'},</div>
                <div className='fontSize--h4 marginTop--l1'>What do you want to do today?</div>
            </div>
        </div>
    );
};

export default HomeGreetings;