// Libraries
import React, {useEffect, useState} from 'react';
import {useRouter} from 'next/router';

// Constants & Utils
import {useAuth} from '../context/AuthUserContext';


const withPublic = (Component) => {
    return (props) => {
        const {authUser} = useAuth();
        const router = useRouter();

        if (typeof window !== 'undefined') {
            if (!authUser) {
                return <Component {...props} />;
            } else {
                router.push('/').then();
                return null;
            }
        }
        return null;
    };
};

const withProtected = (Component, redirectTo = '/login') => {
    return (props) => {
        const {auth, authUser, loading} = useAuth();
        const router = useRouter();
        const [verified, setVerified] = useState(false);

        useEffect(async () => {
            if (!loading) {
                if (!auth || !authUser) {
                    router.push(redirectTo).then();
                } else setVerified(true);
            }
        }, [auth, loading, authUser]);

        if (verified) return <Component {...props} />;
        else return null;
    };
};
export {withPublic, withProtected};
