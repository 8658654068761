// Libraries
import Head from 'next/head';
import React, {Fragment} from 'react';

// Components
import Header from '../layout/Header';
import HomeGreetings from '../components/home/HomeGreetings';
import HomeNavigation from '../components/home/HomeNavigation';

// Utils and Constants
import {withProtected} from '../utils/useAuthRoutes';

const Home = () => {
    return (
        <Fragment>
            <Head>
                <title>Hand of King - FamPay</title>
                <link rel="icon" href="/favicon.ico"/>
            </Head>

            <Header hideNavigation={true}/>

            <main
                className='mainBody homePage pagePadd__x pagePadd__y display--flex flex__flow--column flex__alignItems--center flex__justifyContent--start'>
                <div className='paddTop--l4'>
                    <HomeGreetings/>
                    <HomeNavigation className='marginTop--l8'/>
                </div>
            </main>
        </Fragment>
    );
};

export default withProtected(Home);